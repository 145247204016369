const AllTags = {
    "appartName": {
        "name": "Nom de l'appartement",
        "value": "{{appartName}}"
    },
    "NumberOfNigth": {
        "name": "Nombre de nuits",
        "value": "{{NumberOfNights}}"
    },
    "deviceName": {
        "name": "Nom du Nuki",
        "value": "{{deviceName}}"
    },
    "firstName": {
        "name": "firstName",
        "value": "{{firstName}}"
    },
    "lastName": {
        "name": "lastName",
        "value": "{{lastName}}"
    },
    "urlMain": {
        "name": "urlMain",
        "value": "{{urlMain}}"
    },
    "urlAndroid": {
        "name": "urlAndroid",
        "value": "{{urlAndroid}}"
    },
    "urlIos": {
        "name": "urlIos",
        "value": "{{urlIos}}"
    },
    "urlAndroidPro": {
        "name": "urlAndroidPro",
        "value": "{{urlAndroidPro}}"
    },
    "urlIosPro": {
        "name": "urlIosPro",
        "value": "{{urlIosPro}}"
    },
    "urlPrivacy": {
        "name": "urlPrivacy",
        "value": "{{urlPrivacy}}"
    },
    "urlResetPassword": {
        "name": "urlResetPassword",
        "value": "{{urlResetPassword}}"
    },
    "urlConfirmNewUser": {
        "name": "urlConfirmNewUser",
        "value": "{{urlConfirmNewUser}}"
    },
    "urlOwnerKpiPdf": {
        "name": "urlOwnerKpiPdf",
        "value": '{{urlOwnerKpiPdf}}'
    },
    "clientPhone": {
        "name": "clientPhone",
        "value": "{{clientPhone}}"
    },
    "firstNameTraveller": {
        "name": "firstNameTraveller",
        "value": "{{firstNameTraveler}}"
    },
    "lastNameTraveler": {
        "name": "lastNameTraveller",
        "value": "{{lastNameTraveler}}"
    },
    "reservationNumber": {
        "name": "ReservationNumber",
        "value": "{{ReservationNumber}}"
    },
    "numberOfAdults": {
        "name": "NumberOfAdults",
        "value": "{{NumberOfAdults}}"
    },
    "numberOfChilds": {
        "name": "NumberOfChilds",
        "value": "{{NumberOfChilds}}"
    },
    "checkinDate": {
        "name": "CheckinDate",
        "value": "{{CheckinDate}}"
    },
    "checkoutDate": {
        "name": "CheckoutDate",
        "value": "{{CheckoutDate}}"
    },
    "checkinTime": {
        "name": "CheckinTime",
        "value": "{{CheckinTime}}"
    },
    "checkoutTime": {
        "name": "CheckoutTime",
        "value": "{{CheckoutTime}}"
    },
    "nukiCode": {
        "name": "NukiCode",
        "value": "{{NukiCode}}"
    },
    "totalBookingPrice": {
        "name": "PrixTTC Réservation ",
        "value": "{{totalBookingPrice}}"
    },
    "checkoutMarks": {
        "name": "checkoutMarks",
        "value": "{{checkoutMarks}}"
    },
    "checkoutFeedBack": {
        "name": "checkoutFeedBack",
        "value": "{{checkoutFeedBack}}"
    },
    "prestaTitle": {
        "name": "prestaTitle",
        "value": "{{prestaTitle}}"
    },
    "prestaStartTime": {
        "name": "prestaStartTime",
        "value": "{{prestaStartTime}}"
    },
    "prestaEndTime": {
        "name": "prestaEndTime",
        "value": "{{prestaEndTime}}"
    },
    "firstNameProprio": {
        "name": "firstNameProprio",
        "value": '{{firstNameProprio}}'
    },
    "lastNameProprio": {
        "name": "lastNameProprio",
        "value": '{{lastNameProprio}}'
    },
    "requestReason": {
        "name": "requestReason",
        "value": '{{RequestReason}}'
    },
    "refusalCause": {
        "name": "refusalCause",
        "value": '{{RefusalCause}}'
    }, // motif de refus
    "prestaFirstName": {
        "name": "prestaFirstName",
        "value": '{{prestaFirstName}}'
    },
    "prestaLastName": {
        "name": "prestaLastName",
        "value": '{{prestaLastName}}'
    },
    "validationPictures": {
        "name": "validationPictures",
        "value": '{{validationPictures}}'
    },
    "bookingURL": {
        "name": "bookingURL",
        "value": '{{bookingURL}}'
    },
    "undoneEventsList": {
        "name": "undoneEventsList",
        "value": '{{undoneEventsList}}'
    },
    "codeName": {
        "name": "codeName",
        "value": '{{codeName}}'
    },
    "codeUsageDate": {
        "name": "codeUsageDate",
        "value": '{{codeUsageDate}}'
    },
    "cronHour": {
        "name": "cronHour",
        "value": '{{cronHour}}'
    },
    "deboursUrl": {
        "name": "deboursUrl",
        "value": '{{deboursUrl}}'
    },
    "email": {
        "name": "email",
        "value": '{{email}}'
    },
    "companyName": {
        "name": "companyName",
        "value": '{{companyName}}'
    },
    "totalPayout": {
        "name": "totalPayout",
        "value": '{{totalPayout}}'
    },
    "axoPublicInvoiceURL": {
        "name": "axoPublicInvoiceURL",
        "value": '{{axoPublicInvoiceURL}}'
    },
    "deboursURLs": {
        "name": "deboursURLs",
        "value": '{{deboursURLs}}'
    },
    "synthesisURL": {
        "name": "synthesisURL",
        "value": '{{synthesisURL}}'
    },
    "customerInvoiceDetailsURL": {
        "name": "customerInvoiceDetailsURL",
        "value": '{{customerInvoiceDetailsURL}}'
    },
    "detailsPDF": {
        "name": "detailsPDF",
        "value": '{{detailsPDF}}'
    },
    "detailsCSV": {
        "name": "detailsCSV",
        "value": '{{detailsCSV}}'
    },
    "prepaymentDetails": {
        "name": "prepaymentDetails",
        "value": '{{prepaymentDetails}}'
    },
    "chronoNumber": {
        "name": "chronoNumber",
        "value": '{{chronoNumber}}'
    },
    "cleaningMark": {
        "name": "cleaningMark",
        "value": '{{cleaningMark}}'
    },
    "bookingOrigin": {
        "name": "bookingOrigin",
        "value": '{{bookingOrigin}}'
    }
}

export default AllTags;
